import ACCOUNT_TYPES from "../types/accountTypes";

const initialState = {
  submitting: false,
  error: null,
  searchLoading: false,
  searchError: null,
  searchResults: null,
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_TYPES.RESET_STATE:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ACCOUNT_TYPES.UPDATE_PROFILE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ACCOUNT_TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ACCOUNT_TYPES.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case ACCOUNT_TYPES.UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ACCOUNT_TYPES.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ACCOUNT_TYPES.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //CHANGE_PROFILE_PASSWORD
    case ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_BEGIN:
      return {
        ...initialState,
        submitting: true,
      };

    case ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //SEARCH_USER_BY_NAME
    case ACCOUNT_TYPES.SEARCH_USER_BY_NAME_BEGIN:
      return {
        ...state,
        searchLoading: true,
        searchError: null,
        searchResults: null,
      };
      
    case ACCOUNT_TYPES.SEARCH_USER_BY_NAME_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        searchResults: action.payload,
      };
      
    case ACCOUNT_TYPES.SEARCH_USER_BY_NAME_FAILURE:
      return {
        ...state,
        searchLoading: false,
        searchError: action.payload.error,
      };

    //FOLLOW_UNFOLLOW_USER
    case ACCOUNT_TYPES.FOLLOW_UNFOLLOW_USER:
      return {
        ...state,
      };

    //SEARCH_USER_BY_ID
    case ACCOUNT_TYPES.SEARCH_USER_BY_ID_BEGIN:
      return {
        ...initialState,
        submitting: true,
      };

    case ACCOUNT_TYPES.SEARCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case ACCOUNT_TYPES.SEARCH_USER_BY_ID_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
