import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";
import Snackbar from "./components/Snackbar";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./utils/azureConfig";
import { StyledMaterialDesignContent } from "./services/constants";
import { CheekyLogoIcon } from "./assets/icons";

const msalInstance = new PublicClientApplication(msalConfig);


const app = process.env.HIDE_APP == 'TRUE' ? (
  <div 
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
      backgroundColor: "black",
      color: "white",
    }}
  >
    <div style={{position: "absolute", top: "20px", left: "20px"}}>
      <CheekyLogoIcon 
        style={{
          width: "100px",
          height: "100px",
        }}
      />
    </div>
    <br />
    <h1>Under Maintenance</h1>
    <h5>Sorry for the inconvenience! We will be back soon.</h5>
  </div>
) : (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense>
        <SnackbarProvider
          maxSnack={4}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}
          hideIconVariant
        >
          <Snackbar />
          <GoogleOAuthProvider clientId="186959731446-df9uo2n55e1gkkiju6q4roesr898h6fe.apps.googleusercontent.com">
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </GoogleOAuthProvider>
        </SnackbarProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
