import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  performDeleteProduct,
  performFetchCartProduct,
} from "../../redux/actionCreators/userCreators";
import { closeCartPopup } from "../../redux/actions/userActions";
import SpinLoader from "../SpinLoader/SpinLoader";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getPrice } from "../../utils";
import { performAddProduct } from "../../redux/actionCreators/userCreators";
import {
  CloseCrossIcon,
  HeartIcon,
  LinkIcon,
  TrashBinIcon,
} from "../../assets/icons";
// import { performFetchCart } from "../../redux/actionCreators/userCreators";

const PAGE = 1;
const PER_PAGE = 100;
const Cart = ({
  fetchCartProduct,
  deleteProduct,
  guest,
  fetching,
  addProduct,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const cartopen = useSelector((state) => state?.user?.isCartOpen);

  const dispatch = useDispatch();
  const [savedCart, setSavedCart] = useState(null);
  const [loading, setLoading] = useState(true);

  const onClosePop = () => {
    dispatch(closeCartPopup());
  };

  // handle Fetch Saved CART Product
  const handleFetchCartProduct = useCallback(
    async (page, limit) => {
      setLoading(true);
      const result = await fetchCartProduct({ page, limit });
      if (result) {
        setSavedCart(result?.docs);
        setLoading(false);
      }
    },
    [fetchCartProduct]
  );

  useEffect(() => {
    if (guest === false) {
      handleFetchCartProduct(PAGE, PER_PAGE);
    }
  }, [guest, handleFetchCartProduct]);

  // Delete Cart Item
  const handleDeleteProduct = async (data) => {
    const newData = {
      type: "Cart",
      productIds: [data],
    };
    const result = await deleteProduct(newData);
    if (result?.status) {
      handleFetchCartProduct(PAGE, PER_PAGE);
      history?.replace({
        pathname,
        state: {
          isCartListUpdated: true,
          productId: data,
        },
      });
    }
  };

  //Total Of Cart
  const total = savedCart
    ?.reduce((total, item) => {
      const rawPrice = item?.product
        ? item?.product?.price
        : item?.price;

      if (isNaN(Number(rawPrice))) {
        return total;
      }

      const priceString = String(rawPrice).replace(/[^0-9.-]+/g, "");
      const price = Number(priceString);
      return total + price;
    }, 0)
    ?.toFixed(2);

  // Checkout Button
  const handleCheckout = () => {
    savedCart?.forEach((item) => {
      window?.open(
        item?.product && item?.product?.affiliateUrl
          ? item?.product?.affiliateUrl
          : item?.product?.url,
        "_blank"
      );
    });
  };

  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: "40%",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "425px",
      maxHeight: "678px",
      height: "calc(80vh - 88px)",
      background: "white",
      marginTop: "calc(80px + 7vh)",
      borderRadius: "16px 0px 0px 16px",
      padding: "18px 24px 0px 32px",
      display: "flex",
      flexDirection: "column",
    },
  }));
  const classes = useStyles();

  const handleDrop = (e) => {
    e.preventDefault();
    const productId = e.dataTransfer.getData("application/json");
    if (productId) {
      handleAddProduct(JSON.parse(productId));
    }
    if (e.target.id === "drag-to-cart-button") {
      e.target.style.backgroundColor = ""; // Remove highlight
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (e.target.id === "drag-to-cart-button") {
      e.target.style.backgroundColor = "rgba(229, 177, 94, 0.5)"; // Highlight button
    }
  };

  // const handleDragLeave = (e) => {
  //   if (e.target.id === "drag-to-cart-button") {
  //     e.target.style.backgroundColor = ""; // Remove highlight
  //   }
  // };

  const handleAddProduct = async (data) => {
    let newData = { type: `Cart`, isNotified: true, productId: data.id };
    await addProduct(newData);
    handleFetchCartProduct(PAGE, PER_PAGE);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={cartopen}
      onClose={onClosePop}
      BackdropProps={{
        invisible: true,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Row>
        <div className="w-100 d-flex justify-content-end">
          <CloseCrossIcon onClick={onClosePop} />
        </div>
      </Row>
      <div className="cart-content-wrapper">
        <span className="cart-header">Cart</span>
        <Row>
          <Col className="col-auto cart-subtext">{savedCart?.length} items</Col>
          <Col className="col-auto cart-text p-0">•</Col>
          <Col className="col-auto cart-text">${total}</Col>
        </Row>
        <div
          className="item-list-srcoll-wrapper mt-3"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="item-list-wrapper">
            {loading ? (
              <Container className="d-flex justify-content-center align-items-center h-75">
                <SpinLoader />
              </Container>
            ) : (
              <div>
                {savedCart?.map((item, index) => (
                  <div key={index} className="list-item-wrapper gap-3">
                    <Col className="image-wrapper">
                      <img
                        src={item?.product ? item?.product?.image : item?.image}
                        alt="cart-product"
                        className="image-container"
                      />
                    </Col>
                    <Col className="item-info-wrapper">
                      <Row>
                        <span className="cart-text">
                          {item.product ? item.product?.title : item?.title}
                        </span>
                      </Row>
                      <Row>
                        <span className="cart-subtext">
                          {item?.product?.brandName}
                        </span>
                      </Row>
                      <Row>
                        <span className="cart-item-price">
                          {item?.product?.sanitizePrice &&
                            getPrice(
                              item?.product?.currency,
                              item?.product?.sanitizePrice
                            )}
                        </span>
                      </Row>
                      <Row className="d-flex align-items-center">
                        <Col
                          className="col-auto"
                          onClick={() =>
                            fetching
                              ? null
                              : handleDeleteProduct(item?.product?._id)
                          }
                        >
                          <TrashBinIcon stroke="black" width="16px" />
                        </Col>
                        <Col className="col-auto p-0">
                          <HeartIcon stroke="black" width="17px" />
                        </Col>
                        <Col
                          className="col-auto"
                          onClick={() => {
                            window.open(
                              item?.product && item?.product?.affiliateUrl
                                ? item?.product?.affiliateUrl
                                : item?.product?.url,
                              "_blank"
                            );
                          }}
                        >
                          <LinkIcon />
                        </Col>
                      </Row>
                    </Col>

                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button className="checkout-btn" onClick={handleCheckout}>
            Checkout all
          </button>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  fetching: state.user.fetching,
  guest: state.auth.guest,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCartProduct: (payload) => dispatch(performFetchCartProduct(payload)),
  deleteProduct: (payload) => dispatch(performDeleteProduct(payload)),
  addProduct: (payload) => dispatch(performAddProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
