import React, { useCallback, useEffect, useState } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import NoImage from "../../assets/images/no-image-1.svg";
import giftlistIcon from "../../assets/images/giftlist.svg";

import {
  performCreateYourLists,
  performGetCategoryItemsSelected,
  performGetYourLists,
  performFetchGiftList,
} from "../../redux/actionCreators/userCreators";
import { performFetchProfileByID } from "../../redux/actionCreators/accountCreators"
import { showSnack } from "../../redux/actions/alertActions";
import { validateRoutesAccess } from "../../utils";
import Constants from "../../utils/constants";
import {
  closeWardrobeRoomDrawer,
  openLoginModal,
  openWardrobeRoomDrawer,
} from "../../redux/actions/userActions";
import NextArrow from "../SliderUtils/NextArrow";
import PreviousArrow from "../SliderUtils/PreviousArrow";
import { getRandomColor } from "../../services/utils";
import {
  BellIcon,
  ClosetIcon,
  CompassFilledIcon,
  CompassIcon,
  EyeOpenIcon,
  FilledEyeIcon,
  FilledListsIcon,
  HeartIcon,
  HomeIcon,
  ListsIcon,
  LockIcon,
  PlusIcon,
  ProfileIcon,
  ScreenIcon,
} from "../../assets/icons";
import { CreateNewListModal } from "./CreateNewListModal";
import ImageGrid from "../ImageGrid";

const Sidebar = ({
  guest,
  loggedInUser,
  getYourLists,
  CreateYourList,
  categoryItemSelected,
  list,
  fetching,
  currentUser,
  fetchGiftList,
}) => {
  const { userId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const showRoom = useSelector((state) => state.user.wardrobeRoom);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null); // Set selected List Items
  const [wishlistSelected, setWishlistSelected] = useState(false); // Set selected List Items
  const [giftlistSelected, setGiftlistSelected] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false); // For Creat New List button Position Change to List Top
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [sidebarLists, setSidebarLists] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [giftlistSize, setGiftlistSize] = useState(0);
  const activeUserId = userId || currentUser?._id;
  const auth = useSelector(state => state.auth); 

  const DISCLOSURE_STATEMENT =
    "This website contains affiliate links. We may earn a commission if you make a purchase through these links, at no additional cost to you.";

    useEffect(() => {
      if (auth.profileData || auth.guest) {
        setIsLoadingUser(false);
      }
      
      // console.log('Auth State:', {
      //   profileData: auth.profileData,
      //   guest: auth.guest,
      //   loading: isLoadingUser
      // });
    }, [auth]);

  const fetchGiftlistSize = useCallback(async () => {
    if (guest) return;
  
    try {
      const targetUserId = auth.user?._id;
      
      if (!targetUserId) {
        console.error('No valid user ID available for fetching giftlist');
        return;
      }
  
      const queryParams = {
        userId: targetUserId,
        page: 1,
        limit: 1,  
        filters: {}
      }; 

      const result = await fetchGiftList(queryParams);
  
      if (result?.totalDocs !== undefined) {
        setGiftlistSize(result.totalDocs);
      } else if (Array.isArray(result?.docs)) {
        setGiftlistSize(result.docs.length);
      } else {
        console.error('Unexpected response format:', result);
        setGiftlistSize(0);
      }
    } catch (error) {
      console.error('Error fetching giftlist size:', error);
      setGiftlistSize(0);
    }
  }, [guest, auth.user?._id, fetchGiftList]);
  
  useEffect(() => {
    if (!guest && auth.user?._id) {
      fetchGiftlistSize();
    }
  }, [fetchGiftlistSize, guest, auth.user?._id]);
    
  
  const fetchSidebarLists = useCallback(async () => {
    if (guest) return;

    let data = { page };

    try {
      const result = await getYourLists(data);
      if (result && result.data && result.data.docs) {
        const formattedData = result.data.docs.map((item) => ({
          title: item?.title, 
          id: item?._id,
          isPublic: item?.isPublic,
          isActive: item?.isActive,
          isOwnList: item?.userId === loggedInUser?._id,
          ownerName: item?.ownerName,
          isLiked: item?.isLiked,
          productsCount: item?.productsCount,
          images: item?.images
        }));
        formattedData.wishlistSize = result.data.wishlistSize;
        setSidebarLists(formattedData);

        // fetchGiftlistSize();
      } else {
        console.error("Unexpected API response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching lists:", error);
    }
  }, [getYourLists, guest, loggedInUser]);

  useEffect(() => {
    fetchSidebarLists(page);
  }, [fetchSidebarLists, page]);

  useEffect(() => {
    if (location?.state?.isListUpdated) {
      fetchSidebarLists();
      history.replace({
        pathname: location.pathname,
        state: null,
      });
    }
  }, [location?.state?.isListUpdated, fetchSidebarLists, history]); // fetchGiftlistSize (add when adding giftlist back)

  //handle Login Modal
  const handleLoginModalOpen = () => {
    dispatch(openLoginModal());
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setOptions([]);
    setSelectedItemIndex(null);
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const setData = (result) => {
    const data = [];
    if (result && result.docs) {
      result.docs.forEach((item, index) => {
        const objData = {
          title: item?.title,
          id: item?._id,
          isPublic: item?.isPublic,
          isActive: item?.isActive,
          isOwnList: item?.userId === loggedInUser?._id,
          ownerName: item?.ownerName,
        };
        data.push(objData);
      });
    } else {
      console.log("No docs found in result");
    }
    const yourListsRoute = routes.find(
      (route) => route.key === Constants.ROLE_ATTRIBUTES.YOURLISTS
    );
    if (yourListsRoute) {
      yourListsRoute.children = data;
    }
    setAllowedRoutes([...routes]);
  };

  // UseEffect For sidebar array
  const updateAllowedRoutes = useCallback(() => {
    const routesToUse = path.includes("/account/") ? accountRoutes : routes;
    const { children } = validateRoutesAccess(
      { children: routesToUse },
      loggedInUser ? loggedInUser.role : undefined
    );
    const allowedMenus = [];
    children.forEach((childRoute) => {
      if (childRoute?.isValidRole) {
        allowedMenus.push(childRoute);
      }
    });
    setAllowedRoutes(allowedMenus);
  }, [loggedInUser, path]);

  useEffect(() => {
    updateAllowedRoutes();

    // For Creat New List button Position Change to List Top
    const listContainer = document.querySelector(".your-lists-items-container");
    const listItems = document.querySelector(".your-lists-items");

    if (listContainer && listItems) {
      const isOverflow = listItems.scrollHeight > listContainer.offsetHeight;
      setIsOverflowing(isOverflow);
    }
  }, [loggedInUser, path, updateAllowedRoutes]);

  const FetchCategoryItemSelected = useCallback(async () => {
    setCategoryLoading(true);
    const result = await categoryItemSelected();
    if (result) {
      const data = result;
      const newOptions = data?.map((item) => ({
        value: item?.categoryItemId._id,
        label: item?.categoryItemId.name,
        backgroundColor: getRandomColor(),
      }));

      setOptions(newOptions);
    }
    setCategoryLoading(false);
  }, [categoryItemSelected]);
  useEffect(() => {
    if (guest === false && showModal) {
      FetchCategoryItemSelected();
    }
  }, [FetchCategoryItemSelected, guest, showModal]);

  //styling list items CSS
  function handleItemClick(index) {
    setSelectedItemIndex(index);
    setWishlistSelected(false); // Deselect wishlist
  }

  function handleWishlistClick() {
    setSelectedItemIndex(null); // Deselect other items
    setWishlistSelected(true); // Set wishlist as selected
    history.push("/wishlist");
  }

  function handleGiftlistClick() {
    setSelectedItemIndex(null);
    setWishlistSelected(false);
    setGiftlistSelected(true);
  
    if (auth.guest) {
      handleLoginModalOpen();
      return;
    }
    
  
    // Get target user ID from auth.user i
    const targetUserId = auth.user?._id;
    
    if (!targetUserId) {
      console.error('No valid user ID available:', {
        auth,
        targetUserId,
        isLoading: isLoadingUser
      });
      dispatch(showSnack({
        message: "Unable to access gift list. Please try again.",
        options: { variant: "error" },
      }));
      return;
    }
  
  history.push({
      pathname: `/giftlist/${targetUserId}`,
      state: { userId: targetUserId }
    });
  }
  
  useEffect(() => {
    if (auth.user || auth.guest) {
      setIsLoadingUser(false);
    }
  }, [auth]);

  function handleNavItemClick() {
    setSelectedItemIndex(null); // Deselect other items
    setWishlistSelected(false); // Deselect wishlist
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: (
      <NextArrow
        customStyle={{
          top: "-5%",
          right: "-5%",
          color: "#7a7a7a",
          border: "1px solid #7a7a7a",
          background: "#fff",
          borderRadius: "50%",
        }}
        buttonSize={32}
        slidesToShow={3}
        gradientStyle={{
          background:
            "linear-gradient(270deg, #FFF 69.79%, rgba(255, 255, 255, 0.00) 100%)",
          height: "100%",
          width: "15%",
          zIndex: 2,
          right: "-7%",
        }}
      />
    ),
    prevArrow: (
      <PreviousArrow
        customStyle={{
          top: "-5%",
          left: "-5%",
          color: "#7a7a7a",
          border: "1px solid #7a7a7a",
          borderRadius: "50%",
          backgroundColor: "#fff",
        }}
        gradientStyle={{
          background:
            "linear-gradient(90deg, #FFF 69.79%, rgba(255, 255, 255, 0.00) 100%)",
          width: "15%",
          height: "100%",
          zIndex: 2,
          left: "-7%",
        }}
        buttonSize={32}
      />
    ),
  };

  const activeCart = path.includes("/wardrobe/activeCart");
  const outfit = path.includes("/wardrobe/outfit");
  const purchase = path.includes("/wardrobe/purchased");

  useEffect(() => {
    if ((activeCart || outfit || purchase) && showRoom) {
      dispatch(openWardrobeRoomDrawer());
    } else {
      dispatch(closeWardrobeRoomDrawer());
    }
  }, [path]);

  const shouldRenderFilledIconCheck = (pathname) => {
    return history?.location?.pathname === pathname;
  };

  const routes = [
    {
      key: Constants.ROLE_ATTRIBUTES.DASHBOARD,
      validateRole: false,
      route: "/dashboard",
      name: "Home",
      selected: shouldRenderFilledIconCheck("/dashboard"),
      icon: (
        <HomeIcon
          size="22px"
          fill={shouldRenderFilledIconCheck("/dashboard") ? "#fff" : ""}
        />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.EXPLORE,
      validateRole: false,
      route: "/explore",
      name: "Explore",
      selected: shouldRenderFilledIconCheck("/explore"),
      icon: shouldRenderFilledIconCheck("/explore") ? (
        <CompassFilledIcon size="22px" />
      ) : (
        <CompassIcon size="22px" />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.WARDROBE,
      validateRole: false,
      route: "/wardrobe/purchased",
      name: "Closet",
      selected: shouldRenderFilledIconCheck("/wardrobe/purchased"),
      icon: (
        <ClosetIcon
          size="22px"
          fill={
            shouldRenderFilledIconCheck("/wardrobe/purchased") ? "#fff" : ""
          }
        />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.YOURLISTS,
      validateRole: false,
      route: "/your-lists",
      name: "Lists",
      selected: shouldRenderFilledIconCheck("/your-lists"),
      icon: shouldRenderFilledIconCheck("/your-lists") ? (
        <FilledListsIcon />
      ) : (
        <ListsIcon size="22px" />
      ),
      children: [],
    },
  ];

  const accountRoutes = [
    {
      key: Constants.ROLE_ATTRIBUTES.EDITPROFILE,
      validateRole: false,
      route: "/account/edit",
      name: "Edit Profile",
      selected: shouldRenderFilledIconCheck("/account/edit"),
      icon: (
        <ProfileIcon
          fill={shouldRenderFilledIconCheck("/account/edit") ? "#fff" : ""}
        />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.PRIVACY,
      validateRole: false,
      route: "/account/privacy-settings",
      name: "Account Privacy",
      selected: shouldRenderFilledIconCheck("/account/privacy-settings"),
      icon: shouldRenderFilledIconCheck("/account/privacy-settings") ? (
        <FilledEyeIcon />
      ) : (
        <EyeOpenIcon height="22px" />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.NOTIFICATIONS,
      validateRole: false,
      route: "/account/notification-settings",
      name: "Notifications",
      selected: shouldRenderFilledIconCheck("/account/notification-settings"),
      icon: (
        <BellIcon
          fill={
            shouldRenderFilledIconCheck("/account/notification-settings")
              ? "#fff"
              : ""
          }
        />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.SECURITY,
      validateRole: false,
      route: "/account/security-settings",
      name: "Security",
      selected: shouldRenderFilledIconCheck("/account/security-settings"),
      icon: (
        <LockIcon
          fill={
            shouldRenderFilledIconCheck("/account/security-settings")
              ? "#fff"
              : ""
          }
        />
      ),
    },
    {
      key: Constants.ROLE_ATTRIBUTES.DISPLAY,
      validateRole: false,
      route: "/account/display-settings",
      name: "Display",
      selected: shouldRenderFilledIconCheck("/account/display-settings"),
      icon: (
        <ScreenIcon
          fill={
            shouldRenderFilledIconCheck("/account/display-settings")
              ? "#fff"
              : ""
          }
        />
      ),
    },
  ];

  const handleNavClick = (route, index) => {
    if (guest) {
      if (route == "/explore") {
        history.push("/explore");
        return;
      }
      handleLoginModalOpen();
      return;
    }
    handleNavItemClick(index)
  }

  return (
    <>
      <aside className={`${showRoom ? "room-show" : ""}`}>
        <div component="div">
          <Nav className="flex-column" style={{ gap: "30px" }}>
            {allowedRoutes.map(({ route, name, icon, children }, index) => (
              <div key={`nav-item-${index}`}>
                <NavItem
                  as={NavLink}
                  to={!guest && route}
                  className={`${showRoom && "justify-content-center"}`}
                  onClick={() =>
                    handleNavClick(route, index)
                  }
                >
                  <div className="sidebar-items">
                    <div className="sidebar-navigation">
                      {icon}
                      {!isMobile && !showRoom ? <span>{name}</span> : null}
                    </div>
                    {name === "Lists" ? (
                      <PlusIcon
                        stroke={"white"}
                        onClick={() =>
                          guest ? handleLoginModalOpen() : handleShow()
                        }
                        hoverColor={"gray"}
                      />
                    ) : null}
                  </div>
                </NavItem>
                {!isMobile && !showRoom && name === "Lists" && (
                  <>
                    <div className="your-lists-items-container">
                      <ul className="your-lists-items">
                        <li
                          className={
                            wishlistSelected
                              ? "your-lists-items__wishlist selected"
                              : "your-lists-items__wishlist"
                          }
                          onClick={() =>
                            guest
                              ? handleLoginModalOpen()
                              : handleWishlistClick()
                          }
                        >
                          <div className="image-wrapper">
                            <HeartIcon fill="black" stroke="black" />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="title">Wants</span>
                            <span className="subtitle">{sidebarLists.wishlistSize ?? 0} items</span>
                          </div>
                        </li>
                        {/* <li
                          className={
                            giftlistSelected
                              ? "your-lists-items__wishlist selected"
                              : "your-lists-items__wishlist"
                          }
                          onClick={() =>
                            guest
                              ? handleLoginModalOpen()
                              : handleGiftlistClick()
                          }
                        >
                          <div className="image-wrapper">
                            <img 
                              src={giftlistIcon}
                              alt="Gift List"
                              className="list-cover"
                              style={{ width: '22px', height: '22px' }} 
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="title">Gift List</span>
                            <span className="subtitle">{giftlistSize} items</span>
                          </div>
                        </li> */}
                        {sidebarLists.length > 0 ? (
                          sidebarLists.map((item, index) => (
                            <li
                              className={
                                selectedItemIndex === index
                                  ? "your-lists-items__wishlist selected"
                                  : "your-lists-items__wishlist"
                              }
                              onClick={() => {
                                handleItemClick(index);
                                history.push(`/list-items/${item?.id}`, {
                                  data: item?.title,
                                });
                              }}
                              key={`list-item-${index}-${item?.id}`}
                            >
                              <div className="image-wrapper">
                                <ImageGrid images={(item?.images || []).slice(0, 4)} />
                              </div>
                              <div className="d-flex flex-column">
                                <span className="title">{item?.title}</span>
                                <span className="subtitle">{item?.productsCount} items</span>
                              </div>
                            </li>
                          ))
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            ))}
          </Nav>
          {/* <div className="disclosure-statement">
            <small>{DISCLOSURE_STATEMENT}</small>
          </div> */}
        </div>
      </aside>
      {showModal && (
        <CreateNewListModal
          fetching={fetching}
          guest={guest}
          handleClose={handleClose}
          showModal={showModal}
          loggedInUser={loggedInUser}
          CreateYourList={CreateYourList}
          getYourLists={getYourLists}
          setSidebarLists={setSidebarLists}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.profileData,
    list: state.user.list,
    guest: state.auth.guest,
    fetching: state.user.fetching,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getYourLists: (payload) => dispatch(performGetYourLists(payload)),
  CreateYourList: (payload) => dispatch(performCreateYourLists(payload)),
  categoryItemSelected: (payload) =>
    dispatch(performGetCategoryItemsSelected(payload)),
  fetchGiftList: (payload) => dispatch(performFetchGiftList(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

const customStylesSelect = {
  control: (base, state) => ({
    ...base,
    border: "2px solid #000000 !important",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
    background: "#F5F5F5 !important",
    color: "white !important",
    appearance: "none",
    padding: "0.2rem",
    borderRadius: "4px",
    fontSize: "14px",
    width: "100%",
    minHeight: "48px",
    outline: "none !important",
    fontWeight: "600",
    transition: "all 0.2s ease, letter-spacing 0s",
  }),

  valueContainer: (base) => ({
    ...base,
    gap: "0.3rem !important",
  }),

  multiValue: (base, { data }) => {
    return {
      ...base,
      backgroundColor: data.backgroundColor,
      borderRadius: "20px",
      padding: "3px 6px",
      alignItems: "center",
    };
  },
  multiValueLabel: (base) => ({
    ...base,
    fontFamily: "Arial-Bold",
    fontSize: "16px",
    lineHeight: "100%",
    fontWeight: "700",
    letterSpacing: " 0.005em",
    color: "#000000",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#000000",
    cursor: "pointer",
    height: "24px",
    width: "24px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000000",
    },

    "& > svg": {
      width: "100%",
      height: "100%",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    display: "none",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "gray",
  }),
};
