import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Avatar from "../Avatar";
import { Container, Form, FormControl, Nav, Navbar } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clear from "../../assets/images/clear.svg";
import logosmall from "../../assets/images/logosmall.svg";
import searchicon from "../../assets/images/searchicon.svg";
import searchtime from "../../assets/images/searchtime.svg";
import SignupModal from "../../containers/Auth/Modals/SignupModal";
import { performSearchUserByName } from "../../redux/actionCreators/accountCreators";
import { openCartPopup, openLoginModal } from "../../redux/actions/userActions";
import DropdownMenu from "../DropdownMenu";
import Cart from "../Cart";
import NotificationBox from "../NotificationBox";
import LoginModal from "../../containers/Auth/Modals/LoginModal";
import ReactSelect from "../../components/Form/Select";
import { getImageUrl } from "../../services/utils";
import { DEFAULT_PROFILE_IMAGE } from "../../services/constants";
import { MdOutlineFeedback } from "react-icons/md";
import { useMemo } from "react";
import _ from "lodash";

import {
  BellIcon,
  CheekyLogoIcon,
  CloseCrossThinIcon,
  ResetIcon,
  SearchIcon,
  ShoppingBagIcon,
} from "../../assets/icons";
import { Icon } from "@mui/material";
import { guestLoginReset } from "../../redux/actions/authActions";

const options = [
  { value: "", label: "All" },
  { value: "profile", label: "Profile" },
  { value: "list", label: "List" },
  { value: "product", label: "Product" },
  // { value: "subCategory", label: "Tags" },
];

const Header = ({
  handleLogout,
  user,
  searchUserByName,
  guest,
  isCartOpen,
  loginModalOpen,
}) => {
  const history = useHistory();
  const resultsRef = useRef(null);
  const inputRef = useRef(null);
  const inputFieldRef = useRef(null);
  const dropdownRef = useRef(null);
  const [signupModal, setSignupModal] = useState(false); //Signup Modal
  const [loginModal, setLoginModal] = useState(false); //Login Modal
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false); //Controlling Search Div behaviour
  const [notificationSlide, setNotificationSlide] = useState(false);
  const [searchUserResult, setSearchUserResult] = useState([]);
  const [searchListResult, setSearchListResult] = useState([]);
  const [searchProductResult, setSearchProductResult] = useState([]);
  const [searchTagResult, setSearchTagResult] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [combinedData, setCombinedData] = useState({
    products: [],
    tags: [],
  });
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Handle Logo Change
  const [searchType, setSearchType] = useState("");
  const showRoom = useSelector((state) => state.user.wardrobeRoom);
  const [signupModalData, setSignupModalData] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Handle Logout
  const handleUserLogout = () => {
    handleLogout();
  };

  const searchTypeHandler = (type) => {
    if (type?.value === "All") {
      setSearchType({ ...type, value: "" });
    } else {
      setSearchType(type);
    }
  };

  const handleSearchSubmit = async (e) => {
    if (e) e.preventDefault();
    if (!searchTerm.trim() || guest || isSearching) return;
  
    try {
      setIsSearching(true);
      setShow(false); // Hide preview 
      
      // Navigate to search results page
      history.push(`/search-results?query=${searchTerm}`, {
        initialSearch: true,
        searchTerm: searchTerm.trim(),
        gender: user?.gender // Pass gender to search results
      });
  
      if (inputRef.current) {
        inputRef.current.blur();
      }
  
      // Update recent searches
      setRecentSearches((prevSearches) => {
        if (prevSearches.includes(searchTerm)) return prevSearches;
        const updatedSearches = [searchTerm, ...prevSearches?.slice(0, 3)];
        localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
        return updatedSearches;
      });
    } finally {
      setIsSearching(false);
    }
  };

  // Handle Logo Change
  useLayoutEffect(() => {
    function updateScreenSize() {
      setIsSmallScreen(window.innerWidth <= 768);
    }

    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const showNotificaton = () => {
    setNotificationSlide(true);
  };
  const hideNotificaton = () => {
    setNotificationSlide(false);
  };

  //Cart Drawer Modal
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(openCartPopup());
  };

  //handle Login Modal
  const handleLoginModalOpen = () => {
    dispatch(openLoginModal());
  };

  // Search User

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Load recent searches from localStorage
  useEffect(() => {
    const storedRecentSearches = localStorage.getItem("recentSearches");
    if (storedRecentSearches) {
      setRecentSearches(JSON.parse(storedRecentSearches));
    }
  }, []);

  const handlePreviewSearch = async (searchValue) => {
    if (!searchValue.trim() || guest) return;
  
    try {
      const data = { 
        search: searchValue.trim(),
        searchType: searchType?.value,
        preview: true,
        gender: user?.gender || "" 
      };
      
      const result = await searchUserByName(data);
      if (result) {
        setShow(true);
        setSearchUserResult(result?.data?.userSearch);
        setSearchListResult(result?.data?.list);
        setSearchTagResult(result?.data?.subCategory);
        
        // Filter products by user gender
        const genderFilteredProducts = result?.data?.products?.filter(product => 
          !product.gender || product.gender === user?.gender
        );
        
        setSearchProductResult(genderFilteredProducts?.slice(0, 3));
      }
    } catch (error) {
      console.error('Preview search error:', error);
    }
  };

  const debouncedPreviewSearch = useMemo(
    () => _.debounce(async (searchValue) => {
      if (!searchValue?.trim() || guest || searchValue.length < 2) return;
      
      try {
        const data = { 
          search: searchValue.trim(),
          searchType: 'product', // Only search products for preview
          preview: true,
          gender: user?.gender || "",
          pageSize: 3 // Limit to 3 results
        };
        
        const result = await searchUserByName(data);
        
        if (result?.data?.products) {
          setShow(true);
          setSearchProductResult(result.data.products.slice(0, 3));
          
          // Clear other results 
          setSearchUserResult([]);
          setSearchListResult([]);
          setSearchTagResult([]);
        }
      } catch (error) {
        console.error('Preview search error:', error);
      }
    }, 500), // 500ms debounce time 
    [guest, user?.gender]
  );

  // Use memoized debounced function
  useEffect(() => {
    if (!guest && searchTerm?.length >= 2) {
      debouncedPreviewSearch(searchTerm);
    } else {
      setShow(false);
    }
    
    return () => {
      debouncedPreviewSearch.cancel();
    };
  }, [searchTerm, guest, debouncedPreviewSearch]);

  // Cancel pending debounced calls
  const handleClearSearch = () => {
    debouncedPreviewSearch.cancel();
    setSearchTerm('');
    setSearchProductResult([]);
    setSearchUserResult([]);
    setSearchListResult([]);
    setSearchTagResult([]);
    setShow(false);
  };

  

  useEffect(() => {
    let delayDebounceFn;
  
    if (guest === false && searchTerm) {
      delayDebounceFn = setTimeout(() => {
        handlePreviewSearch(searchTerm);
      }, 300);
    } else {
      setShow(false);
    }
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, guest]);

  // Clear Recent Search Results in search
  const handleClearRecentSearch = (index) => {
    setRecentSearches((prevSearches) => {
      const updatedSearches = [...prevSearches];
      updatedSearches.splice(index, 1);
      localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  // Put Recent Search into search
  const handleClickRecentSearch = (item) => {
    setSearchTerm(item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !resultsRef.current?.contains(event.target) &&
        !dropdownRef.current?.contains(event.target) &&
        !inputRef.current?.contains(event.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Goto The Searched User Profile page
  const handleUserProfileRedirect = (id) => {
    history.push(`/people-profile/${id}`);
    setShow(false);
  };

  // Goto List page
  const handleListRedirect = (item) => {
    history.push({
      pathname:
        user?._id === item?.userId
          ? `/list-items/${item?.listId}`
          : `/people/${item?.userId}/user-list/${item?.listId}`,
      state: {
        data: item?.listTitle,
      },
    });
    setShow(false);
  };

  // Goto Search Result page
  const handleSearchResultRedirect = () => {
    handleSearchSubmit();
  };
  
  const handleCloseSignupModal = () => {
    setSignupModal(false);
    setSignupModalData({});
  };

  // Login Modal Open
  const handleCloseLoginModal = () => {
    setLoginModal(false);
  };

  // Signup Modal Open
  const handleShowSignupModal = (item) => {
    // e.stopPropagation();
    dispatch(guestLoginReset())
    history.push(`/sign-up`);
    // setSignupModal(true);
    // handleCloseLoginModal();
    // if (item) {
    //   setSignupModalData(item);
    // }
    // history.push(`/sign-up`);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const processSearchIconColor = () => {
    if (inputRef?.current === document?.activeElement) {
      return "white";
    }
    if (isHovered) {
      return "#B8B8B8";
    }
    return "#B8B8B8";
  };

// useEffect(() => {
//   if (redirect) {
//     history.push(`/search-results?query=${searchTerm}`, { data: combinedData});
//     inputRef.current.blur();
//   }
//   setRedirect(false);
// }, [redirect, history, searchTerm]);

  return (
    <>
      <Navbar sticky="top" className="header">
        <Container fluid style={{ paddingInline: "35px" }}>
          <Navbar.Brand
            as={RouterLink}
            to={{
              pathname: "/dashboard",
              state: { isListUpdated: guest ? false : true },
            }}
          >
            {isSmallScreen || showRoom ? (
              <div style={{ marginLeft: "16px" }}>
                <img src={logosmall} alt="logosmall" width={32} height={32} />
              </div>
            ) : (
              <CheekyLogoIcon />
            )}
          </Navbar.Brand>

          <Form
            className="search-bar-container"
            style={{
              left: showRoom && "150px",
            }}
            onSubmit={handleSearchSubmit}
          >
            <div
              className="d-flex w-100 input-wrapper"
              style={{
                borderColor:
                  inputRef?.current === document?.activeElement || isHovered
                    ? "#fff"
                    : "transparent",
              }}
            >
              <div className="search-icon-container">
                <SearchIcon stroke={processSearchIconColor()} />
              </div>
              <FormControl
                type="text"
                ref={inputRef}
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="What're you looking for?"
                className="search-input"
                // onFocus={() => {
                //   setShow(true);
                // }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {searchTerm && (
                <img
                  src={clear}
                  alt="clear"
                  className="pointer me-3 ms-1"
                  onClick={() => {
                    // preview-related state updates
                    // setSearchListResult([]);
                    // setSearchUserResult([]);
                    // setSearchTagResult([]);
                    // setSearchProductResult([]);
                    handleClearSearch();
                  }}
                />
              )}
            </div>
            {show && (
              <div className="search-results" ref={resultsRef}>
                {/* Product Search */}
                {searchProductResult?.length > 0 && (
                  <>
                    <p className="category-heading">Products :</p>
                    <div className="card-search-list">
                      {searchProductResult?.map((item, index) => (
                        <div
                          className="user-card"
                          key={index}
                          onClick={() => handleSearchResultRedirect()}
                        >
                          <div className="profile-image bg-white m-2 rounded-circle">
                            <img
                              className="rounded-circle   "
                              src={
                                item?.image
                                  ? item?.image
                                  : DEFAULT_PROFILE_IMAGE
                              }
                              alt="profile"
                              style={{
                                objectFit: "contain",
                                objectPosition: "center",
                                height: "100%",
                                width: "40px",
                              }}
                            />
                          </div>
                          <div className="user-details">
                            <p
                              style={{ color: "#fff" }}
                              className="text-truncate-1"
                            >
                              {item?.title}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {/* Lists Search */}
                <>
                  {searchListResult?.length > 0 && (
                    <>
                      <p className="category-heading">Lists :</p>
                      <div className="lists-result">
                        {searchListResult?.map((item, index) => (
                          <div key={index} className="search-list-container">
                            <SearchIcon size="16px" stroke="#fff" />
                            <p
                              onClick={() => handleListRedirect(item)}
                              className="search-text-result"
                            >
                              {item?.listTitle}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>

                <>
                  {searchUserResult?.length > 0 && (
                    <>
                      <p className="category-heading">Users :</p>
                      <div className="card-search-list">
                        {searchUserResult?.map((item, index) => (
                          <div
                            className="user-card"
                            key={index}
                            onClick={() => handleUserProfileRedirect(item?._id)}
                          >
                            <div className="p-2">
                              <Avatar
                                size="40px"
                                src={item?.profileImage}
                                round={true}
                              />
                            </div>
                            <div className="user-details">
                              <h3>{`${item?.firstName || ""} ${
                                item?.lastName || ""
                              }`}</h3>
                              {item?.username && (
                                <h6>{`@${item?.username || ""}`}</h6>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>

                {/* Tags Search */}
                {searchTagResult?.length > 0 && (
                  <div className="suggested-tabs-container d-flex align-items-stretch my-2">
                    <p className="category-heading">Tags :</p>
                    {searchTagResult?.map((item, index) => (
                      <div
                        className="suggested-tabs"
                        key={index}
                        onClick={() => {
                          history.push({
                            pathname: "/explore/list",
                            state: { data: item },
                          });
                          setShow(false);
                        }}
                      >
                        <span>{item?.subCategory}</span>
                      </div>
                    ))}
                  </div>
                )}

                {!searchUserResult?.length &&
                  !searchListResult?.length &&
                  !searchTagResult?.length &&
                  !searchProductResult?.length && (
                    <>
                      <p className="result-text">No results found</p>
                    </>
                  )}
                <div className="recent-search-container">
                  <p className="recent-search-heading">Recent Searches</p>
                  <div className="recent-search">
                    {recentSearches?.slice(1)?.map((item, index) => (
                      <div className="recent" key={index}>
                        <div className="name-and-recent-icon ">
                          <div>
                            <ResetIcon />
                          </div>
                          <span
                            className="search-text-result"
                            onClick={() => handleClickRecentSearch(item)}
                          >
                            {item}
                          </span>
                        </div>
                        <CloseCrossThinIcon
                          onClick={() => handleClearRecentSearch(index)}
                          fill="#B8B8B8"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Form>

          <Nav className="ms-auto align-items-center">
            <div className="d-flex justify-content-between align-items-center gap-4">
              {/* Notifications */}
              {/* {!guest && (
                <div
                  className="icon-button"
                  onClick={() => showNotificaton()}
                >
                  <div className="notification-dot">5</div>
                  <BellIcon />
                </div>
              )} */}
              <div 
                className="icon-button"
                onClick={() => window.location.href = "https://www.cheeky.shopping/feedback"}
              >
                <MdOutlineFeedback size="28px" className="feedback-icon"/>
              </div>
              {/* Shopping bag */}
              <div
                onClick={() =>
                  guest ? handleLoginModalOpen() : handleToggle()
                }
                className="icon-button"
              >
                <ShoppingBagIcon size="25px" />
              </div>
              {/* Shopping bag */}
              {/* Avatar dropdown */}
              {guest ? (
                <div className="header-buttons">
                  <button
                    className="header-login"
                    onClick={() => handleLoginModalOpen()}
                  >
                    <span>Login</span>
                  </button>

                  <button
                    className="header-signup"
                    onClick={(e) => handleShowSignupModal(e)}
                  >
                    <span>Signup</span>
                  </button>
                </div>
              ) : (
                <DropdownMenu
                  menu={[
                    {
                      label: "Profile",
                      onClick: () => history.push("/account"),
                    },

                    {
                      label: "Settings",
                      onClick: () => history.push("/account/edit"),
                    },
                    {
                      label: "Sign Out",
                      onClick: () => handleUserLogout(),
                    },
                  ]}
                  icon={
                    <Avatar 
                      size="32px" 
                      round={true} 
                      src={user?.profileImage}
                    />}
                />
              )}
              {/* Avatar dropdown */}
            </div>
          </Nav>
        </Container>
      </Navbar>
      <NotificationBox
        notificationSlide={notificationSlide}
        hideNotificaton={hideNotificaton}
      />
      {loginModalOpen && (
        <LoginModal
          showModal={loginModal}
          handleCloseModal={handleCloseLoginModal}
          setSignupModalData={setSignupModalData}
          handleShowSignupModal={handleShowSignupModal}
        />
      )}
      {signupModal && (
        <SignupModal
          showModal={signupModal}
          handleCloseModal={handleCloseSignupModal}
          data={signupModalData?.mobile || signupModalData?.email}
        />
      )}
      {isCartOpen && <Cart onClose={handleToggle} />}
    </>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  guest: state.auth.guest,
  isCartOpen: state.user.isCartOpen,
  loginModalOpen: state.user.isLoginModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  searchUserByName: (payload) => dispatch(performSearchUserByName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
const customStylesSelect = {
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    background: "#fff !important",
    width: "max-content !important",
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),

  input: (base, state) => ({
    ...base,
    height: "unset",
    color: "#242424 !important",
    fontWeight: "500",
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: "0",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#242424 !important",
    fontWeight: "500",
  }),
  singleValue: (base, state) => ({
    ...base,
    lineHeight: "125%",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    marginRight: "0.5rem",
    paddingLeft: "0",
  }),

  menu: (base) => ({
    ...base,
    backgroundColor: "#121212 !important",
    padding: "0px 10px !important",
    width: "7rem !important",
    borderRadius: "8px !important",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: isFocused ? "#121212 !important" : null,
    borderBottom: "1px solid #7a7a7a !important",
    color: isSelected ? "#fff" : "#a7a7a7",
    "&:last-of-type ": {
      borderBottom: "0 !important",
    },
    "&:hover ": {
      color: "#fff",
    },
  }),
};
